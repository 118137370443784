// services/error-snackbar.service.ts
import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarRef,
  TextOnlySnackBar,
} from '@angular/material/snack-bar';
import { ErrorCode } from '@codenteam/core/errors';

@Injectable({ providedIn: 'root' })
export class ErrorSnackbarService {
  private queue: Array<{ message: string; code: ErrorCode }> = [];
  private currentSnackbar: MatSnackBarRef<TextOnlySnackBar> | null = null;
  private whenSnackbarCloses: (errorCode: ErrorCode) => void = null;

  constructor(private snackBar: MatSnackBar) {}
  attachFunctionWhenBarSnackCloses(
    whenSnackbarCloses: (errorCode: ErrorCode) => void
  ) {
    this.whenSnackbarCloses = whenSnackbarCloses;
  }
  showError(message: string, code: ErrorCode) {
    this.queue.push({ message, code });
    if (!this.currentSnackbar) {
      this.processNext();
    }
  }

  private processNext() {
    if (this.queue.length === 0) return;

    const nextError = this.queue.shift()!;
    // Open Default snackbar
    this.currentSnackbar = this.snackBar.open(
      `${nextError.message}`,
      'Dismiss',
      {
        duration: this.getDuration(nextError.code),
        panelClass: ['error-snackbar'],

        horizontalPosition: 'center',
        verticalPosition: 'bottom',
      }
    );

    this.currentSnackbar.afterDismissed().subscribe(() => {
      this.currentSnackbar = null;
      if (this.whenSnackbarCloses) this.whenSnackbarCloses(nextError.code);
      this.processNext();
    });
  }

  private getDuration(code: ErrorCode): number {
    // Custom durations based on error type
    switch (code) {
      case ErrorCode.VALIDATION_ERROR:
        return 8000;
      case ErrorCode.PARALLEL_ONGOING_ERROR:
        return 6000;
      case ErrorCode.AUTHENTICATION_FAILED:
        return 5000;
      default:
        return 4000;
    }
  }
}
