// error-types.ts
export enum ErrorCode {
  // User-facing
  /**
   * The name should be meaningful enough
   */
  VALIDATION_ERROR = 1001,
  AUTHENTICATION_FAILED = 2001,
  QUOTE_EXCEEDED_ERROR = 2002,
  ACCOUNT_PACKAGE_EXPIRATION_ERROR = 2003,
  QUOTE_EXCEEDED_ERROR_GUEST = 2004,
  EXCEEDED_NUMBER_OF_AI_MESSAGE_WORDS = 2005,
  EXCEEDED_NUMBER_OF_AI_MESSAGE_LIMIT_THIS_MONTH = 2006,
  RECAPTCHA_ERROR = 3003,
  EXTERNAL_API_ERROR = 4001,

  // System
  INTERNAL_SERVER_ERROR = 500,
  PARALLEL_ONGOING_ERROR = 4004,
  SUSPICIOUS_OPERATION_ERROR = 3004,
}
/**
 * Default messages based on error types
 */
export const ErrorDefaultMessages: Record<ErrorCode, string> = {
  [ErrorCode.VALIDATION_ERROR]: 'Invalid input data',
  [ErrorCode.AUTHENTICATION_FAILED]: 'Authentication failed',
  [ErrorCode.INTERNAL_SERVER_ERROR]:
    'Unexpected error. Please, try again later.',
  [ErrorCode.QUOTE_EXCEEDED_ERROR]: 'Exceeded account quota limit.',
  [ErrorCode.ACCOUNT_PACKAGE_EXPIRATION_ERROR]: 'Account Package has expired.',
  [ErrorCode.RECAPTCHA_ERROR]: 'Please refresh the page and try again.',
  [ErrorCode.EXTERNAL_API_ERROR]:
    'Error Retrieving your information from the external API.', //this message should be always overridden
  [ErrorCode.PARALLEL_ONGOING_ERROR]:
    'Exceed max number of parallel ongoing items.',
  [ErrorCode.SUSPICIOUS_OPERATION_ERROR]:
    'We cannot process this operation, please contact the support for more information.',
  [ErrorCode.EXCEEDED_NUMBER_OF_AI_MESSAGE_WORDS]:
    'Message exceeds the allowed length. Please shorten your message and try again.',
  [ErrorCode.EXCEEDED_NUMBER_OF_AI_MESSAGE_LIMIT_THIS_MONTH]:
    'You have reached the maximum number of AI messages allowed for this month.',
  [ErrorCode.QUOTE_EXCEEDED_ERROR_GUEST]:
    'We cannot proceed with this process. Please contact the account owner or reach out to our support team for assistance.',
};

export class ErrorResponse {
  code: ErrorCode;
  status?: number;
  message: string;
  details?: any;
}
